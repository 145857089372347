import React from 'react';
import SEO from '../components/seo';
import './stylesheets/general.scss';
import '../components/index.scss';
import Loader from '../components/loader';
import ContextConsumer from '../components/context';
import { Link } from 'gatsby';

const EN = require('../components/copy/EN.json');
const IT = require('../components/copy/IT.json');
let currentLanguage;
let language;

const img1 = require('../images/wool.jpeg');

class Wool extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: false });
  }

  loader() {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  renderIndex() {
    return (
      <>
        <ContextConsumer>
          {({ data }) => {
            language = data.contextLanguage;

            language === 'IT' ? (currentLanguage = IT) : (currentLanguage = EN);
            return (
              <div className="general-container">
                <SEO title="Bocciardo - Wool & Cashmere" />
                <h2>{currentLanguage.Wool.title}</h2>
                <div className="img-wool-container">
                  <img src={img1} />
                </div>
                <p>{currentLanguage.Wool.text}</p>
                <Link
                  to="/contact_us"
                  style={{
                    textDecorationColor: 'black',
                  }}
                >
                  <p className="contact">{currentLanguage.Wool.informazioni}</p>
                </Link>
              </div>
            );
          }}
        </ContextConsumer>
      </>
    );
  }

  render() {
    return <>{this.renderIndex()}</>;
  }
}

export default Wool;
